import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import request from 'request-promise';

import SECRETS from './secrets';


const styles = (theme) =>  ({
  EmergenciaScreen: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 420,
  },
  margin: {
    marginTop: 20,
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '100%',
  },
  mensajes: {
    backgroundColor: '#333',
    color: 'white',
    paddingLeft: '5px',
    paddingRight: '5px',
  },
});

class Login extends Component {
  state = {
    logged: true,
    latitud: 0.0,
    longitud: 0.0,
    interval: 0,
    counter: 0,
    response: 'Haz click en el botón de Emergencia para pedir ayuda al policía más cercano',
  };

  componentDidMount = () => {
    this.getLocation();
    const intervalId = setInterval(this.getLocation, 1000 * 60);
    this.setState({ intervalId });
  };

  componentWillUnmount = () => {
    const { intervalId } = this.state;
    clearInterval(intervalId);
  };

  getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => this.setState({
          latitud: position.coords.latitude,
          longitud: position.coords.longitude,
        }),
      );
    }
  };

  emergencia = () => {
    const { getCookie } = this.props;
    const { latitud, longitud, counter } = this.state;

    if (navigator.geolocation) {
      if (latitud === 0 || longitud === 0) {
        this.setState({
          counter: counter + 1,
          response: 'No hemos podido acceder a tu ubicación, cierra y abre la aplicación.',
        });
        return;
      }
      // Everything is fine
      request({
        method: 'POST',
        uri: `${SECRETS.serverUrl}/api/v1/sos`,
        body: {
          latitud,
          longitud,
        },
        json: true,
        headers: {
          'Authorization': getCookie('token'),
        },
      })
        .then((resp) => {
          console.log(resp);
          const response = 'Se ha pedido ayuda al policía más cercano.';
          this.setState({
            counter: counter + 1,
            response,
          });
        })
        .catch((err) => {
          if (err.statusCode === 403) {
            this.logout();
          }
          if (err.statusCode === 404) {
            const response = err.error.message;
            this.setState({
              counter: counter + 1,
              response,
            });
          }
          console.error(err);
        });
    } else {
      this.setState({
        counter: counter + 1,
        response: 'Por favor permite el acceso a tu ubicación',
      });
    }
  };

  logout = () => {
    const { removeCookie } = this.props;
    removeCookie('token');
    removeCookie('isCivil');
    this.setState({ logged: false });
  };

  handleChange = name => ev => this.setState({ [name]: ev.target.value });

  render = () => {
    const { classes, getCookie, } = this.props;
    const {
      logged,
      counter,
      response,
    } = this.state;
    if (!(getCookie('token') && getCookie('isCivil'))) return <Redirect to="/" />;
    if (!logged) return <Redirect to="/" />;
    return (
      <div className={classes.EmergenciaScreen}>
        <Typography variant="subtitle2" gutterBottom className={classes.mensajes}>
          {`${counter}: ${response}`}
        </Typography>
        <div className={classes.form}>
          <Button
            size="large"
            color="primary"
            variant="contained"
            className={classes.margin}
            onClick={this.emergencia}
          >
            Emergencia
          </Button>
          <Button
            size="large"
            color="primary"
            variant="contained"
            className={classes.margin}
            onClick={this.logout}
          >
            Salir
          </Button>
        </div>
      </div>
    );
  };
}

export default withStyles(styles)(Login);
