import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import { HashRouter, Route } from "react-router-dom";
import Login from './Login';
import New from './New';
import Main from './Main';

class App extends Component {
  getCookie = (name) => {
    const { cookies } = this.props;
    return cookies.get(name);
  };

  setCookie = (name, value) => {
    const { cookies } = this.props;
    cookies.set(name, value, { path: '/' });
  };

  removeCookie = (name) => {
    const { cookies } = this.props;
    cookies.remove(name);
  }

  render() {
    return (
        <div className="App">
          <HashRouter>
            <div>
              <Route
                exact
                path="/"
                render={() => (
                  <Login
                    getCookie={this.getCookie}
                    setCookie={this.setCookie}
                  />
                )}
              />
              <Route
                exact
                path="/new"
                render={() => (
                  <New
                  />
                )}
              />
              <Route
                exact
                path="/main"
                render={() => (
                  <Main
                    getCookie={this.getCookie}
                    setCookie={this.setCookie}
                    removeCookie={this.removeCookie}
                  />
                )}
              />
            </div>
          </HashRouter>
        </div>
    );
  }
}

export default withCookies(App);
